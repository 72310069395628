<template>
	<div id="teacherCheckDetails" v-loading="loading" :style="{ height: height + 'px' }">
		<div class="head u-f-item u-f-jsb">
			<div>{{ name }}的请假记录</div>
		</div>
		<div class="content">
			<div class="title u-f-item u-f-jsb">
				<div>共{{ total }}条数据</div>
				<!-- <el-button type="primary" size="mini" @click="exportsHeart">导出</el-button> -->
			</div>
			<el-table :data="list" height="95%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;" key="2">
				<el-table-column label="序号" type="index" width="70" align="center"></el-table-column>
				<el-table-column label="请假开始时间" prop="start_time" align="center"></el-table-column>
				<el-table-column label="请假结束时间" prop="end_time" align="center"></el-table-column>
				<el-table-column label="请假天数" prop="day" align="center"></el-table-column>
				<el-table-column label="请假说明" prop="explain" align="center"></el-table-column>
				<el-table-column label="请假类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type==1">事假</span>
						<span v-if="scope.row.type==2">病假</span>
						<span v-if="scope.row.type==3">婚假</span>
						<span v-if="scope.row.type==4">产假</span>
						<span v-if="scope.row.type==5">陪产假</span>
						<span v-if="scope.row.type==6">哺乳假</span>
						<span v-if="scope.row.type==7">丧假</span>
						<span v-if="scope.row.type==8">公假</span>
						<span v-if="scope.row.type==9">法定假</span>
					</template>
				</el-table-column>
				<el-table-column label="申请时间" prop="createtime" align="center"></el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			height: 500,
			loading: false,
			id: '',
			list: [],
			name: '',
			isShow:false,
			status:1,
			list_id:'',
			info:'',
			total:0
		};
	},
	created() {},
	mounted() {
		this.height = document.documentElement.clientHeight || document.body.clientHeight;
		this.id = this.$route.query.id;
		this.name = this.$route.query.username;
		this.getList();
	},
	methods: {
		exportsHeart(){
			let data = {
				id: this.id
			};
			this.$api.teachers.teacherLeavedetails(data).then(res => {
				if (res.data.code == 1) {
					this.$message.success('导出成功');
					location.href = res.data.data;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		getList() {
			let data = {
				id: this.id
			};
			this.$api.teachers.teacherLeavedetails(data).then(res => {
				if (res.data.code == 1) {
					this.list = res.data.rows;
					this.total = res.data.total;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		caozuo(e){
			this.status = e.status;
			this.list_id = e.list_id;
			this.isShow = true;
		},
		handleClose(done){
			done()
		},
		submitadd(){
			this.$api.setting.checkOpera({
				id:this.id,
				status:this.status,
				list_id:this.list_id
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('操作成功');
					this.isShow = false;
					this.getList();
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss" scope>
#teacherCheckDetails {
	.head {
		background-color: #4998ff;
		padding: 15px 20px;
		div {
			color: #fff;
			font-size: 16px;
		}
	}
	.content {
		background-color: #ffffff;
		margin-top: 10px;
		padding: 0 30px;
		height: calc(100% - 80px);
		overflow-y: auto;
		.title {
			padding: 0 0 10px 0;
			.btn {
				background-color: #f5f5f5;
				padding: 5px;
			}
			.btn:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
		}
		.el-table th > .cell,
		.el-table .cell {
			overflow: hidden; /*超出部分隐藏*/
			white-space: nowrap; /*不换行*/
			text-overflow: ellipsis; /*超出部分文字以...显示*/
		}
		.el-table thead {
			background: #fafafa !important;
		}
		.el-table th {
			padding: 15px 5px !important;
			background: #fafafa !important;
		}
		.el-table::before {
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
		}
		.el-table .btn {
			padding: 5px;
			text-align: center;
		}
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		.con-box {
			border-top: 1px solid #eee;
			margin-top: 15px;
			max-height: 400px;
			overflow-y: auto;
			> div {
				margin-top: 10px;
				background-color: #f6f8fb;
				padding: 15px;
				> div:first-child {
					margin-right: 30%;
				}
			}
			> div.none {
				color: #d8dddf;
			}
			> div.can:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
			> div.selected {
				background-color: #4998ff;
				color: #fff;
			}
		}
		.page {
			margin-top: 10px;
			text-align: right;
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
